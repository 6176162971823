import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import YouTube from 'react-youtube';
import FacebookPlayer from 'react-facebook-player';
import {
	Select,
	TextField,
	RichTextEditor,
	Slider
} from '../../../components/form';
import { FileSelector, ImageSelector } from '../../../components/modals';
import Gallery from '../../../components/form/Gallery';
import { openSnackbar } from '../../../store/snackbar/actions';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { open, setOpen, onConfirm, t } = props;

	const classes = useStyles();

	const createYoutubeIdFromUrl = (url) => {
		const videoId = url.match(
			/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
		);
		if (videoId != null) {
			return videoId[1];
		}
		return null;
	};
	const createFacebookIdFromUrl = (url) => {
		const videoId = url.match(
			/(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-zA-Z0-9\.]+\/)?([0-9]+)/
		);
		if (videoId != null) {
			return videoId[1];
		}
		return null;
	};

	const overrideValues = (values) => {
		let content = values.content.map((item) => {
			if (item.youtubeId) {
				return { ...item, youtubeId: createYoutubeIdFromUrl(item.youtubeId) };
			}
			return item;
		});

		content = values.content.map((item) => {
			if (item.facebookId) {
				return {
					...item,
					facebookId: createFacebookIdFromUrl(item.facebookId)
				};
			}
			return item;
		});

		const sliderContent = values.slider.content.filter(
			(item) => item.fileId !== null
		);
		const galleryContent = values.gallery.content.filter(
			(item) => item.fileId !== null
		);

		return {
			category: values.category,
			slider: { content: sliderContent },
			gallery: { content: galleryContent },
			content
		};
	};

	return (
		<Formik
			initialValues={{
				category: 'events',
				content: [
					{
						lang: 'hu'
					},
					{
						lang: 'ro'
					}
				],
				slider: {
					content: [
						{
							num: 1,
							fileId: null
						},
						{
							num: 2,
							fileId: null
						},
						{
							num: 3,
							fileId: null
						},
						{
							num: 4,
							fileId: null
						},
						{
							num: 5,
							fileId: null
						},
						{
							num: 6,
							fileId: null
						}
					]
				},
				gallery: { content: [{ fileId: null, num: 1 }] }
			}}
			onSubmit={(
				values,
				{ setSubmitting, setErrors, setStatus, resetForm }
			) => {
				onConfirm(overrideValues(values))
					.then((result) => {
						if (result) {
							resetForm({});
							setOpen(false);
						}
					})
					.catch((e) => {
						if (e.response.status === 406) {
							openSnackbar({
								message:
									'Sikertelen mentés! Töltsd ki az összes csillaggal megjelölt mezőt!',
								severity: 'error'
							});
						}
					});
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>{t('newelementNews')}</DialogTitle>
					<DialogContent>
						<Form>
							<Field
								name='category'
								label={t('category')}
								options={[
									{
										value: 'events',
										label: t('common:newsCreateCategoryEvents')
									},
									{
										value: 'announcements',
										label: t('common:newsCreateCategoryAnnouncements')
									},
									{
										value: 'communications',
										label: t('common:newCreateCategoryCommunications')
									}
								]}
								component={Select}
								size='small'
								value={values.category}
								onChange={(evt) => setFieldValue('category', evt.target.value)}
							/>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => {
											const validYoutubeLink =
												item.youtubeId &&
												createYoutubeIdFromUrl(item.youtubeId);
											const validFacebookLink =
												item.facebookId &&
												createFacebookIdFromUrl(item.facebookId);
											return (
												<Box key={index} className={classes.fieldArrayItem}>
													<Typography variant='h2'>
														{t(`common:${item.lang}`)}
													</Typography>

													<Field
														error={!values.content[index].title}
														name={`content[${index}].title`}
														label={t('common:newsCreateTitle') + '*'}
														component={TextField}
													/>
													<Field
														name={`content[${index}].youtubeId`}
														label={t('common:youtubeId')}
														component={TextField}
													/>
													{values.content[index].youtubeId && (
														<>
															{validYoutubeLink ? (
																<YouTube
																	videoId={createYoutubeIdFromUrl(
																		item.youtubeId
																	)}
																	id={createYoutubeIdFromUrl(item.youtubeId)}
																/>
															) : (
																<p style={{ color: '#e53935', fontSize: 10 }}>
																	{t('common:youtubeLinkIsInvalid')}
																</p>
															)}
														</>
													)}
													<Field
														name={`content[${index}].facebookId`}
														label={t('common:facebookId')}
														component={TextField}
													/>
													{values.content[index].facebookId && (
														<>
															{validFacebookLink ? (
																<FacebookPlayer
																	videoId={createFacebookIdFromUrl(
																		item.facebookId
																	)}
																	appId={361360825797642}
																/>
															) : (
																<p style={{ color: '#e53935', fontSize: 10 }}>
																	{t('common:facebookLinkIsInvalid')}
																</p>
															)}
														</>
													)}
													<Field
														error={!values.content[index].shortDesc}
														name={`content[${index}].shortDesc`}
														label={`${t('common:newsCreateShortDesc')} *`}
														component={TextField}
														multiline
														rows={2}
													/>

													<p
														style={{
															marginTop: 32,
															fontFamily: 'Ubuntu',
															color: values.content[index].content
																? 'black'
																: '#e53935'
														}}>
														{`${t('common:newsCreateContent')} *`}
													</p>
													<Field
														name={`content[${index}].content`}
														component={RichTextEditor}
														defaultValue={item.content}
														size='small'
														error={!values.content[index].content}
														label={t('common:newsCreateContent')}
													/>
													<Box
														style={{
															marginTop: 27
														}}>
														<p
															style={{
																fontFamily: 'Ubuntu',
																color: values.content[index].headerImageId
																	? 'black'
																	: '#e53935'
															}}>
															Borítókép*
														</p>
														<ImageSelector
															fieldName={`content[${index}].headerImageId`}
															setImageId={(id) => {
																setFieldValue(
																	`content[${index}].headerImageId`,
																	id
																);
															}}
														/>
													</Box>
													<Box
														style={{
															marginTop: 27,
															display: 'flex',
															flexDirection: 'column'
														}}>
														<p style={{ fontFamily: 'Ubuntu' }}>Csatolmány</p>

														<FileSelector
															fieldName={`content[${index}].attachmentId`}
															setFileId={(id) => {
																setFieldValue(
																	`content[${index}].attachmentId`,
																	id
																);
															}}
														/>
													</Box>
												</Box>
											);
										})}
									</Box>
								)}
							/>
							<Typography variant='h2'>{t('sliderimages')}</Typography>
							<Slider values={values.slider} setFieldValue={setFieldValue} />

							<Typography variant='h2'>{t('gallery')}</Typography>
							<Gallery
								values={values.gallery}
								gallery
								setFieldValue={setFieldValue}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='secondary'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='default'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
