const navigationConfig = (page) => {
	if (page === 'lives') {
		return [
			{
				name: 'home',
				href: '/'
			},
			{
				name: 'lives',
				href: '/lives'
			},
			{
				name: 'neigh',
				href: '/sights/neigh'
			},
			{
				name: 'around',
				href: '/sights/around'
			},
			{
				name: 'hotels',
				href: '/hotels'
			},
			{
				name: 'history',
				href: '/history',
				subMenu: [
					{
						name: 'siculicidium',
						href: '/history/siculicidium'
					},
					{
						name: 'madefalva',
						href: '/history/madefalva'
					},
					{
						name: 'monument',
						href: '/history/monument'
					}
				]
			}
		];
	}
	if (page === 'office') {
		return [
			{
				name: 'home',
				href: '/'
			},
			{
				name: 'news',
				href: '/news'
			},
			{
				name: 'gazette',
				href: '/gazette'
			},
			{
				name: 'office-page',
				href: '/council'
			},
			// {
			// 	name: 'community',
			// 	href: '/community'
			// },
			{
				name: 'amade-tv',
				href: '/amade-tv'
			},
			{
				name: 'careers',
				href: '/gazette/careers'
			},
			{
				name: 'elections',
				href: '/gazette/elections'
			},
			{
				name: 'transparency',
				href: '/gazette/transparency'
			},
			{
				name: 'procurements',
				href: '/procurements'
			},
			{
				name: 'touches',
				href: '',
				goToLivePage: true
			}
		];
	}
	return [
		{
			name: 'home',
			href: '/'
		}
	];
};

export default navigationConfig;
