import React, { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Typography
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FileResults } from '../../views/Files/File/components/Results';
import { getFiles } from '../../store/file/actions';
import { Dropzone } from '../../views/Files/File/components/Dropzone';
import { FILE_TYPE } from '../../store/types';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
	btnContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 8
	},
	attachments: {
		display: 'flex',
		flexDirection: 'column'
	},
	attachment: {
		borderRadius: '4px',
		padding: '8px',
		backgroundColor: '#48B3C9',
		color: '#070C35',
		marginTop: 8,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	}
}));

function MultipleFileSelect(props) {
	const { t, setFileList, disableRemove } = props;
	const [open, setOpen] = useState(false);
	const [file, setFile] = useState(props.file ? props.file : null);
	const [selectedFiles, setSelectedFiles] = useState(props.files || null);
	const files = useSelector((state) => state.file.files);
	const classes = useStyles();

	useEffect(() => {
		fileList();
	}, []);

	const fileList = () => {
		if (files && files.meta) {
		} else {
			getFiles({ limit: 18, page: 1 });
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const selectItem = (file) => {
		const existed = selectedFiles
			? !!selectedFiles.find((item) => item.id === file.id)
			: false;
		if (!existed) {
			const list = selectedFiles ? [...selectedFiles, file] : [file];
			setSelectedFiles(list);
			setOpen(false);
			setFileList(list);
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={classes.attachments}>
				<Typography variant='h3' style={{ color: '#070C35', marginTop: 16 }}>
					Csatolmányok
				</Typography>
				{selectedFiles &&
					selectedFiles.map((item, index) => {
						return (
							<div key={index} className={classes.attachment}>
								<Typography variant='h4'>
									{item.url.substring(
										item.url.lastIndexOf('/') + 1,
										item.url.length
									)}
								</Typography>
								{!disableRemove && (
									<CloseIcon
										onClick={() => {
											const fileList = selectedFiles.filter(
												(file) => file.id !== item.id
											);
											setSelectedFiles(fileList);
											setFileList(fileList);
										}}
									/>
								)}
							</div>
						);
					})}
			</div>
			{!disableRemove && (
				<div className={classes.btnContainer}>
					<Button variant='outlined' color='primary' onClick={handleClickOpen}>
						{t('selectFile')}
					</Button>
					<Dropzone
						type={FILE_TYPE.ATTACHMENT}
						selected={(id) => {
							selectItem(id);
						}}
					/>
				</div>
			)}

			{file && (
				<Typography>
					{t('selectedFile')}:{' '}
					{file.url.substring(file.url.lastIndexOf('/') + 1, file.url.length)}{' '}
				</Typography>
			)}

			<Dialog
				fullWidth
				maxWidth='lg'
				open={open}
				onClose={handleClose}
				aria-labelledby='max-width-dialog-title'>
				<DialogTitle id='max-width-dialog-title'>
					{t('dialogtitle')}
				</DialogTitle>
				<DialogContent>
					<FileResults selector selectItem={selectItem} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						{t('close')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default withNamespaces('fileSelector')(MultipleFileSelect);
