import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Route as BaseRoute, withRouter, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import menuItems from './menus/menuItem/menuItems';
import layout from '../../themes/layout';
import { Home } from '../../views/public/Home';
import { showLargeMenu } from '../../store/layout/actions';
import { checkLanguage } from '../../store/page/language/actions';
import { AcceptCookies } from '../AcceptCookies';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: '100px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: 64
		}
	},
	adminContainer: {
		paddingLeft: 'calc(' + layout.menu.width + ' + 20px)',
		paddingRight: 20,
		marginTop: 64,
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		[theme.breakpoints.down('xs')]: {
			padding: '20px'
		},
		overflow: 'hidden',
		'& *': {
			boxSizing: 'border-box'
		}
	}
}));

const AuthRoute = (props) => {
	const me = useSelector((state) => state.me);
	const classes = useStyles();
	const { menuItem, children, ...others } = props;
	return me && me.role ? (
		menuItem.roles.includes(me.role) ? (
			<BaseRoute path={menuItem.href} exact {...others}>
				<Box p={1} className={classes.adminContainer}>
					{children}
				</Box>
			</BaseRoute>
		) : (
			<RedirectRoute />
		)
	) : (
		<RedirectRoute />
	);
};

const RedirectRoute = (props) => {
	const { ...others } = props;
	const classes = useStyles();
	return (
		<BaseRoute path='/' exact {...others}>
			<Box className={classes.container}>
				<Home />
			</Box>
		</BaseRoute>
	);
};

const Route = (props) => {
	const classes = useStyles();
	const acceptedCookies = useSelector((state) => state.cookies.accepted);
	const [cookiesIsAccepted, setAcceptedCookies] = useState(true);
	const location = useLocation();

	const { children, name, path, ...others } = props;
	const me = useSelector((state) => state.me);
	const menuItemData = menuItems.filter((item) => item.name === name)
		? menuItems.filter((item) => item.name === name)[0]
		: null;
	useEffect(() => {
		if (location.pathname != '/') showLargeMenu(false);
	}, [props]);

	useEffect(() => {
		if (!checkLanguage()) {
			props.history.push('/language');
		}
	}, []);

	useEffect(() => {
		if (!acceptedCookies && checkLanguage()) {
			setAcceptedCookies(false);
		} else {
			setAcceptedCookies(true);
		}
	}, [acceptedCookies]);

	return (
		<>
			{menuItemData ? (
				menuItemData.public ? (
					<BaseRoute {...others} path={path} exact>
						<Box
							className={
								me && me.role ? classes.adminContainer : classes.container
							}>
							{children}
						</Box>
					</BaseRoute>
				) : (
					<AuthRoute
						{...others}
						exact
						path={menuItemData.href}
						menuItem={menuItemData}>
						{children}
					</AuthRoute>
				)
			) : (
				<RedirectRoute {...others} />
			)}
			{!cookiesIsAccepted && <AcceptCookies />}
		</>
	);
};

export default withRouter(Route);
