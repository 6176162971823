import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import { getFiles } from '../../../../../store/file/actions';
import { File } from './components/File';

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20
	},
	pagination: {
		'& > *': {
			marginTop: theme.spacing(2)
		}
	}
}));

const FileResults = (props) => {
	const { selector, selectItem } = props;

	const files = useSelector((state) => state.file.files);
	const classes = useStyles();

	const onPageChange = (event, value) => {
		getFiles({ limit: 18, page: value });
	};

	return (
		<>
			{files && files.meta && (
				<div>
					<Grid container spacing={4} className={classes.gridContainer}>
						{files.items &&
							files.items.map((file) => (
								<Grid item xs={12} sm={6} md={2} key={file.id}>
									<File
										showDelete={props.showDelete}
										file={file}
										selector={selector}
										selectItem={selectItem}
									/>
								</Grid>
							))}
					</Grid>
					<div className={classes.pagination}>
						<Pagination
							count={files.meta.totalPages}
							color='primary'
							onChange={onPageChange}
						/>
					</div>
				</div>
			)}
		</>
	);
};
export default withNamespaces()(FileResults);
