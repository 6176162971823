import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Box,
	Typography,
	useMediaQuery,
	Grid
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'qs';
import Search from './components/Search/Search';
import { FileCard } from '../../Council/components/FileCard';
import {
	Village,
	Statutum,
	Regulations,
	Decisions,
	Provisions,
	Documents,
	OtherDocuments
} from '../../../../components/icons';
import { transformQuery } from '../../../../lib/helpers/queryTransformer';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Pagination from '../../../../components/Pagination/Pagination';
import { getAllOfficialGazette } from '../../../../store/officialGazette/actions';
import { CategoryCard } from '../../Council/components/Category';

const BP1 = '@media (max-width: 540px)';

const useStyles = makeStyles(() => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1164,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 32,
		flex: 1,
		display: 'inline-flex',
		// margin: 'auto',
		marginBottom: 36,
		borderBottom: '1spx solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px'
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		height: 264,
		width: 264,
		background: `transparent url(${Image}) 0% 0% no-repeat padding-box`,
		marginRight: 24
	},
	desciptionContainer: {
		width: 'calc(100% - 264px)'
	},
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		display: 'inline-flex',
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		// height: 73,
		paddingLeft: '24px',
		paddingRight: '24px',
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'center',
		paddingBottom: 10
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		marginLeft: 20,
		// paddingBottom: 40,
		boxSizing: 'border-box',
		// marginBottom: theme.spacing(1),
		[BP1]: {
			fontSize: '22px',
			lineHeight: 'unset'
		}
	}
}));

const getIcons = (isSmall) => {
	const w = `${isSmall ? 75 : 120}px`;
	const h = `${isSmall ? 50 : 80}px`;
	return {
		unit_status: <Statutum width={w} height={h} />,
		admi_procs: <Regulations width={w} height={h} />,
		auth_dec: <Decisions width={w} height={h} />,
		ex_disp: <Provisions width={w} height={h} />,
		fin_docs: <Documents width={w} height={h} />,
		other: <OtherDocuments width='120px' height='80px' />
	};
};

export default withNamespaces('gazette')((props) => {
	const { t } = props;
	const classes = useStyles();
	const params = useParams();
	const data = useSelector((state) => state.gazette.list);
	const history = useHistory();
	const isMobileView = useMediaQuery('(max-width: 540px)');
	const [query, setQuery] = useState({});

	useEffect(() => {
		setQuery(
			qs.parse(history.location.search, {
				ignoreQueryPrefix: true
			})
		);
	}, [history.location.search]);

	const [page, setPage] = useState(1);

	useEffect(() => {
		if (params.cat) {
			getAllOfficialGazette(params.cat, query);
		}
	}, [params.cat, query]);

	const handlePageChange = (e) => {
		if (e.keyCode === 13) {
			if (e.target.value > data?.meta?.totalPages || e.target.value < 1) return;
			setPage(Number.parseInt(e.target.value, 10));
			history.push({
				pathname: `/gazette/${params.cat}`,
				search: transformQuery({
					...query,
					page: e.target.value
				})
			});
			window.scrollTo(0, 0);
		}
	};

	const pushPage = (up) => {
		const nPage = page ? Number(page) : 1;
		const pageTmp = up ? nPage + 1 : nPage - 1;
		// setPage(pageTmp);
		history.push({
			pathname: `/gazette/${params.cat}`,
			search: transformQuery({
				...query,
				page: pageTmp
			})
		});
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (
			data?.meta?.totalPages &&
			query.page &&
			query.page <= data.meta.totalPages
		) {
			setPage(query.page);
		}
	}, [data, query.page]);

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				{params.cat !== 'transparency' &&
					params.cat !== 'careers' &&
					params.cat !== 'elections' &&
					params.cat !== 'council_meetings' &&
					params.cat !== 'decision_plans' && (
						<Box className={classes.titleContainer}>
							<Breadcrumbs
								breadcrumbs={[
									{
										href: '/gazette',
										name: t('title')
									},
									{
										href: `/gazette/${params.cat}`,
										name: t(params.cat),
										final: true
									}
								]}
							/>
						</Box>
					)}
				<Box className={classes.title2Container}>
					{getIcons(isMobileView)[`${params.cat}`]}
					<Typography className={classes.pageTitle}>{t(params.cat)}</Typography>
				</Box>
				{params.cat === 'transparency' ? (
					<>
						<Grid container className={classes.cardsContainer}>
							<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
								<CategoryCard type='council_meetings' />
							</Grid>
							<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
								<CategoryCard type='decision_plans' />
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Search category={params.cat} />
						<Box className={classes.content}>
							{data?.items &&
								data.items.length > 0 &&
								data.items.map((item) =>
									item?.id ? <FileCard key={item.id} file={item} /> : <></>
								)}
						</Box>
						<Pagination
							page={page}
							setPage={setPage}
							handlePageChange={handlePageChange}
							currentPage={data?.meta?.currentPage}
							totalPages={data?.meta?.totalPages ? data.meta.totalPages : 1}
							t={t}
							pushPage={pushPage}
						/>
					</>
				)}
			</Box>
			<Box className={classes.village2Container}>
				<Village />
			</Box>
		</Box>
	);
});
