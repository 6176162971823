import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	CardActionArea,
	CardActions,
	CardMedia,
	Button,
	Card,
	Checkbox,
	Box
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { deleteFile, viewImage } from '../../../../../../../store/file/actions';
import { openSnackbar } from '../../../../../../../store/snackbar/actions';
import { CustomIcon } from '../../../../../../../components/icons';
import { Confirmation } from '../../../../../../../components/modals';
import { FILE_TYPE } from '../../../../../../../store/types';

const useStyles = makeStyles({
	root: {
		width: '100%'
	},
	media: {
		height: 200
	},
	delete: {
		cursor: 'pointer'
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
});

function Image(props) {
	const classes = useStyles();
	const { image, t, selector, selectItem } = props;
	const [selected, setSelected] = useState(false);
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

	const handleChange = (event) => {
		setSelected(event.target.checked);
		selectItem(image);
	};

	const deleteItem = () => {
		deleteFile(image.id, FILE_TYPE.IMAGE);
		viewImage(null);
	};

	return (
		<>
			<Confirmation
				open={openDeleteConfirmation}
				setOpen={setOpenDeleteConfirmation}
				onConfirm={() => deleteItem()}
				title='Biztosan törölni szeretnéd?'>
				Ez a művelet végleges és visszafordíthatatlan!
			</Confirmation>
			<Card
				className={classes.root}
				onClick={() => {
					selector ? selectItem(image) : viewImage(image);
				}}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={`${image.url}?size=sm`}
						title='Contemplative Reptile'
					/>
				</CardActionArea>
				{selector && (
					<Checkbox
						checked={selected}
						onChange={handleChange}
						name='selected'
						color='primary'
					/>
				)}
				<Box className={classes.actions}>
					{!selector && (
						<CardActions>
							<CopyToClipboard
								text={image.url}
								onCopy={() =>
									openSnackbar({
										message: 'Masolva a vagolapra!',
										severity: 'success'
									})
								}>
								<Button size='small' color='primary'>
									{t('common:copy')}
								</Button>
							</CopyToClipboard>
						</CardActions>
					)}
					{!selector && (
						<Box
							className={classes.delete}
							onClick={() => setOpenDeleteConfirmation(true)}>
							<CustomIcon color='primary' icon={'delete'} />
						</Box>
					)}
				</Box>
			</Card>
		</>
	);
}

export default withNamespaces()(Image);
