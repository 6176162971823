import {
	Typography,
	Box,
	makeStyles,
	withWidth,
	Hidden,
	Button
} from '@material-ui/core';
import React from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import {
	ChevronRight,
	Monument,
	HomeScreenHalfCircle,
	HalfCircleStripes,
	StraightStripes,
	DotIcon
} from '../../../../components/icons';
import HomePicSm from '../../../../components/images/IMG_2409.png';
import { livePage } from '../../../../config/locations';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${HomePicSm})`,
		mixBlendMode: 'multiply',
		backgroundSize: 'cover',
		width: '100%',
		height: '100%'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#F0F0EB',
		left: 274,
		width: 760,
		height: 816,
		top: 24,
		[theme.breakpoints.only('md')]: {
			right: 0,
			top: 0
		},
		[theme.breakpoints.only('sm')]: {
			width: 760,
			height: 816,
			right: 0,
			left: 0,
			top: 0
		},
		[theme.breakpoints.only('xs')]: {
			display: 'none',
			top: 0
		}
	},

	firstPageContainer: {
		top: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		height: 800,

		// fixed
		position: 'relative',
		right: 0,
		width: 1128,
		left: 0,
		[theme.breakpoints.only('lg')]: {
			top: -150
		},
		[theme.breakpoints.only('xl')]: {
			top: -150
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	root: {
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			width: '95%'
		},
		['@media screen and (max-height: 750px) and (min-width: 1280px)']: {
			width: '135vw'
		},
		height: 'fit-content'
	},
	pageContainer: {
		width: '100vw',
		[theme.breakpoints.up('lg')]: {
			// height: 'calc(100vh - 100px) ',
			// overflow: 'scroll'
		},
		['@media screen and (max-height: 750px) and (min-width: 1280px)']: {
			width: '135vw'
		}
	},
	titleBox: {
		zIndex: 101,
		top: 262,
		maxWidth: 530,
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		height: 200
	},
	titleContainer: {
		zIndex: 101,
		background: '#FFFFFF',
		position: 'absolute',
		[theme.breakpoints.up('sm')]: {
			top: 388
		},
		[theme.breakpoints.down('sm')]: {
			top: 288,
			left: 30
		},
		[theme.breakpoints.only('xs')]: {
			top: 200,
			left: 20
		}
	},
	titleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: 20,
			paddingBottom: 6,
			paddingTop: 8,
			paddingRight: 46
			// fontSize: 50
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 10,
			paddingBottom: 3,
			paddingTop: 4,
			paddingRight: 23,
			fontSize: 40
		},
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 10,
			paddingRight: 23,
			fontSize: '36px',
			lineHeight: '40px'
		}
	},
	description: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: 24,
		color: theme.palette.primary.dark,
		paddingTop: 25,
		paddingBottom: 25
	},
	navigationIconButton: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF'
		},
		position: 'absolute',
		top: 60,
		transform: 'rotate(90deg)'
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box'
	},
	iconContainer: {
		zIndex: 100,
		position: 'absolute',

		top: 264,
		left: 330,

		[theme.breakpoints.down('sm')]: {
			top: 190,
			left: 260
		},
		[theme.breakpoints.only('xs')]: {
			top: 105,
			left: 130
		}
	},
	bottomcircles: {
		position: 'relative',

		width: 300,
		[theme.breakpoints.up('sm')]: {
			left: 171,
			top: 457
		},
		[theme.breakpoints.down('sm')]: {
			width: 100,
			left: 80,
			top: 340
		}
	},
	halfStripes: {
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			left: 65,
			top: -12
		},
		[theme.breakpoints.only('xs')]: {
			top: -100,
			left: -30
		}
	},
	halfCircle: {
		position: 'relative',

		[theme.breakpoints.up('sm')]: {
			top: -147,
			left: 49
		},
		[theme.breakpoints.down('sm')]: {
			top: -120,
			left: 90
		},
		[theme.breakpoints.only('xs')]: {
			top: -230,
			left: 0
		}
	},
	logo: {
		position: 'absolute',
		top: 65,
		[theme.breakpoints.only('md')]: {
			top: 120,
			left: 20
		},
		[theme.breakpoints.only('xs')]: {
			top: 0,
			left: 20
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 640,
		display: 'inline-flex',
		[theme.breakpoints.only('xs')]: {
			top: 350
		},
		[theme.breakpoints.only('lg')]: {
			top: 600
		}
	},
	descriptionTitle: {
		color: '#E60F37',
		fontFamily: 'Bebas Neue',
		textTransform: 'uppercase',
		fontSize: 30,
		letterSpacing: '0.3px',
		paddingLeft: 24,
		[theme.breakpoints.only('xs')]: {
			fontSize: 25
		}
	},
	descriptionText: {
		width: '100%',
		fontSize: '18px',
		lineHeight: '30px',
		color: '#282D38',
		fontFamily: 'Ubuntu',
		letterSpacing: '0.18px',
		paddingTop: 15,
		paddingLeft: 24,
		[theme.breakpoints.only('xs')]: {
			letterSpacing: '0.1px',
			width: 300,
			fontSize: '16px',
			zIndex: 100
		}
	},
	navigateIconContainer: {
		position: 'relative',
		paddingRight: 64,
		float: 'left',
		width: 100,
		marginTop: 'auto',
		marginBottom: 35
	},
	descriptionBox: {
		position: 'absolute',
		width: 300,
		left: 144,
		[theme.breakpoints.only('xs')]: {
			left: 10,
			width: 300
		},
		[theme.breakpoints.only('sm')]: {
			left: 50
		}
	},
	straightStripesContainer: {
		width: 400,
		position: 'absolute',

		[theme.breakpoints.up('sm')]: {
			top: 548,
			left: 728
		},
		[theme.breakpoints.down('sm')]: {
			width: 300,
			height: 200,
			left: 0,
			zIndex: 0,
			top: 370,
			opacity: 0.2
		},
		[theme.breakpoints.only('md')]: {
			top: 548,
			left: 'auto',
			right: 0,
			width: 300
		}
	},
	dotIconContainer: {
		position: 'absolute',

		[theme.breakpoints.up('sm')]: {
			top: 278,
			left: 488
		},
		[theme.breakpoints.down('sm')]: {
			top: 150,
			left: 400
		},
		[theme.breakpoints.only('xs')]: {
			right: 20,
			left: 'auto'
		}
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		transition: '0.1s all ease',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40
		},
		'&:hover': {
			backgroundColor: '#48B3C9',
			paddingRight: 70,
			paddingLeft: 70,
			marginRight: -10
		}
	},
	buttonContainer: {
		position: 'absolute',
		top: 740,
		left: 'auto',
		right: '77px',
		[theme.breakpoints.only('lg')]: {
			top: 640
		},
		[theme.breakpoints.only('xs')]: {
			top: 500
		}
	},
	ahref: {
		textDecoration: 'none'
	}
}));

function MadefalvaEl(props) {
	const classes = useStyles();
	const { t } = props;

	return (
		<Box className={classes.root} style={{ zIndex: 1 }}>
			<Box className={classes.pageContainer} id='madefalvael'>
				<Box className={classes.firstPageContainer}>
					<Hidden smDown>
						<Box className={classes.imageContainerFixed}>
							<Box className={classes.imageContainer} />
						</Box>
					</Hidden>
					<Hidden mdUp>
						<Box className={classes.imageContainerFixed}>
							<Box className={classes.imageContainer} />
						</Box>
					</Hidden>

					<Box className={classes.straightStripesContainer}>
						<StraightStripes width='100%' height='100%' />
					</Box>

					<Box className={classes.dotIconContainer}>
						<DotIcon width='34px' height='34px' fill='#E0B080' />
					</Box>

					<Box className={classes.infoContainer}>
						<Box className={classes.titleBox}>
							<Hidden only={['sm', 'xs']}>
								<Box className={classes.iconContainer}>
									<Monument fill='#48B3C9' height='126px' dotfill='none' />
								</Box>
							</Hidden>
							<Hidden only={['xl', 'lg', 'md']}>
								<Box className={classes.iconContainer}>
									<Monument fill='#48B3C9' height='100px' dotfill='none' />
								</Box>
							</Hidden>

							<Box className={classes.titleContainer}>
								<Typography variant='h1' className={classes.titleText}>
									{t('homescroll6.title')}
								</Typography>
							</Box>
							<Hidden only={['sm', 'xs']}>
								<Box className={classes.bottomcircles}>
									<Box className={classes.halfStripes}>
										<HalfCircleStripes />
									</Box>
									<Box className={classes.halfCircle}>
										<HomeScreenHalfCircle
											width='192px'
											height='96px'
											fill='#E60F37'
										/>
									</Box>
								</Box>
							</Hidden>
							<Hidden only={['xl', 'lg', 'md']}>
								<Box className={classes.bottomcircles}>
									<Box className={classes.halfStripes}>
										<HalfCircleStripes width='170px' height='127px' />
									</Box>
									<Box className={classes.halfCircle}>
										<HomeScreenHalfCircle
											width='110px'
											height='60px'
											fill='#E60F37'
										/>
									</Box>
								</Box>
							</Hidden>
						</Box>

						<Box className={classes.descriptionContainer}>
							<Box className={classes.descriptionBox}>
								<Typography className={classes.descriptionTitle}>
									{t('homescroll6.descriptiontitle')}
								</Typography>
								<Typography className={classes.descriptionText}>
									<Trans i18nKey='homescroll6.description'>
										<br />
										<br />
									</Trans>
								</Typography>
							</Box>
						</Box>

						<Box className={classes.buttonContainer}>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href={livePage}
								className={classes.ahref}>
								<Button type='button' className={classes.nextButton}>
									<Typography variant='button'>
										{t('homescroll6.button')}
									</Typography>
									<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
								</Button>
							</a>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default withWidth()(withNamespaces('homeMadefalva')(MadefalvaEl));
