import { GET_PROCUREMENTS } from '../../types';

export default function procurements(state = {}, action) {
	switch (action.type) {
		case GET_PROCUREMENTS:
			return action.payload;
		default:
			return state;
	}
}
