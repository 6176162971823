import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../components/images/vintage05.png';

import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle,
	Monument
} from '../../../../components/icons';
import { livePage } from '../../../../config/locations';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'relative',
		// backgroundColor: "#DDE9E6",
		backgroundColor: '#F0F0EB',
		left: '368px',
		width: '850px',
		height: '640px',
		top: '45px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 250,
			left: 0
		},
		[theme.breakpoints.only('sm')]: {
			position: 'absolute',
			width: 600,
			height: 400,
			right: 0,
			left: 'auto',
			top: 100
		},
		[theme.breakpoints.only('md')]: {
			position: 'absolute',
			right: 0,
			left: 'auto'
		}
	},
	pageContainer: {
		position: 'relative',
		height: '900px',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
			marginTop: 0,
			width: '100%',
			height: 'auto'
		},
		[theme.breakpoints.only('lg')]: {
			top: 0,
			height: '800px'
		},
		[theme.breakpoints.only('xl')]: {
			top: 100,
			height: '800px'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0
	},
	contentContainer: {
		top: '0px',
		width: '100%',
		height: '824px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '277px',
		position: 'absolute',
		background: '#FEFEFE 0% 0% no-repeat padding-box',
		textAlign: 'right',
		zIndex: 101
	},
	mainTitleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		marginLeft: '20px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '36px',
			lineHeight: '40px'
		}
	},
	animatedCircles: {
		// position: "absolute",
	},
	halfCircle: {
		position: 'absolute',
		top: '345px',
		left: '95px',
		zIndex: 95,
		width: 192,
		height: 96,
		[theme.breakpoints.only('xs')]: {
			width: 150,
			height: 75,
			left: 'auto',
			right: 20
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '496px',
		width: 386,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 396
		},
		[theme.breakpoints.only('md')]: {
			top: 600,
			left: 30
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 30,
			paddingRight: 20
		}
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		transition: '0.1s all ease',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40
		},
		'&:hover': {
			backgroundColor: '#48B3C9',
			paddingRight: 70,
			paddingLeft: 70,
			marginLeft: -10
		}
	},
	buttonContainer: {
		position: 'absolute',
		top: '627px',

		[theme.breakpoints.only('xs')]: {
			paddingLeft: 30,
			top: 560
		},
		[theme.breakpoints.only('sm')]: {
			paddingLeft: 30
		},
		[theme.breakpoints.only('md')]: {
			top: 720,
			left: 30
		}
	},
	footerIconBox: {
		position: 'absolute',
		top: 632,
		right: 0,
		height: 191,
		[theme.breakpoints.only('xs')]: {
			height: 150,
			top: 527
		}
	},
	descriptionTitle: {
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '2px',
		lineHeight: '30px',
		textTransform: 'uppercase',
		font: 'Bold 30px/30px Bebas Neue',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 20,
			paddingLeft: 30
		}
	},
	halfStripes: {
		top: '206px',
		position: 'absolute',
		zIndex: 100,
		width: 400,
		height: 252,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%',
			zIndex: 90,
			opacity: 0.5
		}
	},
	ahref: {
		textDecoration: 'none'
	}
}));

function SiculicidiumHistory(props) {
	const classes = useStyles();
	const { t } = props;

	return (
		<Box className={classes.pageContainer} style={{ zIndex: 1 }}>
			<Box className={classes.imageContainerFixed}>
				<Box className={classes.imageContainer} />
			</Box>

			<Box className={classes.mainTitleBox}>
				<Typography className={classes.mainTitleText}>
					{t('siculicidiumhistory12.title')}
				</Typography>
			</Box>

			<Box className={classes.animatedCircles}>
				<Box className={classes.halfStripes}>
					<StraightStripes width='100%' height='100%' />
				</Box>

				<Box className={classes.halfCircle}>
					<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
				</Box>
			</Box>

			<Box className={classes.descriptionContainer}>
				<Typography className={classes.descriptionTitle}>
					{t('siculicidiumhistory12.descriptiontitle')}
				</Typography>
				<Typography className={classes.descriptionText} varinat='paragraph'>
					<Trans i18nKey='siculicidiumhistory12.description'>
						<br />
						<br />
					</Trans>
				</Typography>
			</Box>

			<Box className={classes.buttonContainer}>
				<a
					target='_blank'
					href={`${livePage}/history/siculicidium`}
					className={classes.ahref}>
					<Button type='button' className={classes.nextButton}>
						<Typography variant='button'>
							{t('siculicidiumhistory12.button')}
						</Typography>
						<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
					</Button>
				</a>
			</Box>

			<Box className={classes.footerIconBox}>
				<Monument height='100%' fill='#FFFFFF' dotfill='#FFFFFF' />
			</Box>
		</Box>
	);
}

export default withNamespaces('home')(SiculicidiumHistory);
