import React, { useEffect, useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'qs';
import { Village } from '../../../components/icons';
import { transformQuery } from '../../../lib/helpers/queryTransformer';
import { getAllProcurements } from '../../../store/procurements/actions';
import Search from './components/Search/Search';
import Pagination from '../../../components/Pagination/Pagination';
import { NavigationFileCard } from '../Council/components/NavigationFileCard';

const BP1 = '@media (max-width: 540px)';

const useStyles = makeStyles(() => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1164,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 32,
		flex: 1,
		display: 'inline-flex',
		// margin: 'auto',
		marginBottom: 36,
		borderBottom: '1spx solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px'
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		height: 264,
		width: 264,
		background: `transparent url(${Image}) 0% 0% no-repeat padding-box`,
		marginRight: 24
	},
	desciptionContainer: {
		width: 'calc(100% - 264px)'
	},
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		display: 'inline-flex',
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		// height: 73,
		paddingLeft: '24px',
		paddingRight: '24px',
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'center',
		paddingBottom: 10
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		marginLeft: 20,
		// paddingBottom: 40,
		boxSizing: 'border-box',
		// marginBottom: theme.spacing(1),
		[BP1]: {
			fontSize: '22px',
			lineHeight: 'unset'
		}
	}
}));

export default withNamespaces('procurements')((props) => {
	const { t } = props;
	const classes = useStyles();
	const params = useParams();
	const data = useSelector((state) => state.procurements.list);
	const history = useHistory();
	const [query, setQuery] = useState({});
	const language = useSelector((state) => state.language.language);

	useEffect(() => {
		setQuery(
			qs.parse(history.location.search, {
				ignoreQueryPrefix: true
			})
		);
	}, [history.location.search]);

	const [page, setPage] = useState(1);
	useEffect(() => {
		getAllProcurements(query);
	}, [query]);

	const handlePageChange = (e) => {
		if (e.keyCode === 13) {
			if (e.target.value > data?.meta?.totalPages || e.target.value < 1) return;
			setPage(Number.parseInt(e.target.value, 10));
			history.push({
				pathname: `/procurements/`,
				search: transformQuery({
					...query,
					page: e.target.value
				})
			});
			window.scrollTo(0, 0);
		}
	};

	const pushPage = (up) => {
		const nPage = page ? Number(page) : 1;
		const pageTmp = up ? nPage + 1 : nPage - 1;
		// setPage(pageTmp);
		history.push({
			pathname: `/procurements/`,
			search: transformQuery({
				...query,
				page: pageTmp
			})
		});
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (
			data?.meta?.totalPages &&
			query.page &&
			query.page <= data.meta.totalPages
		) {
			setPage(query.page);
		}
	}, [data, query.page]);

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				<>
					<Search category={params.cat} />
					<Box className={classes.content}>
						{data?.items &&
							data.items.length > 0 &&
							data.items?.map((item) => (
								<>
									<NavigationFileCard
										key={item.id}
										title={
											item.content.find((element) => element.lang === language)
												.title
										}
										shortDescr={
											item.content.find((element) => element.lang === language)
												.shortDesc
										}
										id={item.id}
									/>
								</>
							))}
					</Box>
					<Pagination
						page={page}
						setPage={setPage}
						handlePageChange={handlePageChange}
						currentPage={data?.meta?.currentPage}
						totalPages={data?.meta?.totalPages ? data.meta.totalPages : 1}
						t={t}
						pushPage={pushPage}
					/>
				</>
			</Box>
			<Box className={classes.village2Container}>
				<Village />
			</Box>
		</Box>
	);
});
