import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	CardActionArea,
	CardActions,
	CardMedia,
	Button,
	Card,
	Checkbox,
	Box,
	Typography
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { CustomIcon } from '../../../../../../../components/icons';
import { Confirmation } from '../../../../../../../components/modals';
import { deleteFile } from '../../../../../../../store/file/actions';

const useStyles = makeStyles({
	root: {
		width: '100%',
		position: 'relative'
	},
	media: {
		height: 200
	},
	details: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	delete: {
		cursor: 'pointer'
	}
});

function File(props) {
	const { t, file, selector, selectItem } = props;
	const classes = useStyles();
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
	const handleSelect = () => {
		selectItem(file);
	};

	const deleteItem = () => {
		deleteFile(file.id);
	};

	return (
		<Card className={classes.root}>
			<Confirmation
				open={openDeleteConfirmation}
				setOpen={setOpenDeleteConfirmation}
				onConfirm={() => deleteItem()}
				title='Biztosan törölni szeretnéd?'>
				Ez a művelet végleges és visszafordíthatatlan!
			</Confirmation>

			<CardActionArea>
				<CardMedia
					className={classes.media}
					image='https://cdn.pixabay.com/photo/2013/07/12/12/41/text-146110_960_720.png'
					onClick={(event) => {
						if (selector) {
							handleSelect();
						} else {
							window.open(file.url, '_blank');
						}
					}}
				/>
			</CardActionArea>
			<CardActions className={classes.details}>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '80%',
						overflow: 'hidden'
					}}>
					<Typography>
						{file.url.substring(file.url.lastIndexOf('/') + 1, file.url.length)}
					</Typography>
					<Typography color='textSecondary'>
						{moment(file.createdAt).format('YYYY-MM-DD HH:mm:ss')}
					</Typography>
				</Box>
				{props.showDelete && (
					<Box
						className={classes.delete}
						onClick={() => setOpenDeleteConfirmation(true)}>
						<CustomIcon color='primary' icon={'delete'} />
					</Box>
				)}
			</CardActions>
		</Card>
	);
}

export default withNamespaces()(File);
