export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_POST = 'SET_POST';

export const GET_ME = 'GET_ME';

export const LOG_OUT = 'LOG_OUT';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const VIEW_IMAGE = 'VIEW_IMAGE';

export const FILE_TYPE = {
	IMAGE: 'Image',
	ATTACHMENT: 'Attachment'
};

export const GET_ALL_NEWS = 'GET_ALL_NEWS';
export const GET_NEWS = 'GET_NEWS';

export const GET_ALL_PROCUREMENTS = 'GET_ALL_PROCUREMENTS';
export const GET_PROCUREMENT = 'GET_PROCUREMENT';
export const GET_PROCUREMENTS = 'GET_PROCUREMENTS';

export const GET_ALL_COMMUNITY = 'GET_ALL_COMMUNITY';
export const GET_COMMUNITY = 'GET_COMMUNITY';
export const GET_COMMUNITY_CAT = 'GET_COMMUNITY_CAT';
export const GET_ALL_ACTIVE_COMMUNITY = 'GET_ALL_ACTIVE_COMMUNITY';

export const GET_ALL_HOTELS = 'GET_ALL_HOTELS';
export const GET_HOTELS = 'GET_HOTELS';

export const GET_SIGHT = 'GET_SIGHT';
export const GET_SIGHTS = 'GET_SIGHTS';

export const GET_SIGHTS_TO_HOME = 'GET_SIGHTS_TO_HOME';
export const GET_SIGHTS_TO_HOME_MOBILE = 'GET_SIGHTS_TO_HOME_MOBILE';
export const GET_HOTEL_TO_HOME = 'GET_HOTEL_TO_HOME';
export const LOAD_EMPTY_SECTIONS = 'LOAD_EMPTY_SECTIONS';

export const LANGUAGES = {
	EN: 'en',
	HU: 'hu',
	RO: 'ro'
};

export const SHOW_MENU = 'SHOW_MENU';
export const SHOW_LARGE_MENU = 'SHOW_LARGE_MENU';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const SET_FOOTER_REQUIRED = 'SET_FOOTER_REQUIRED';

export const GET_ALL_OFFICIAL_GAZETTE = 'GET_ALL_OFFICIAL_GAZETTE';

export const GET_OFFICE = 'GET_OFFICE';

export const GET_COMMON = 'GET_OFFICE';

export const GET_COUNCIL = 'GET_COUNCIL';

export const SET_ACCEPTED_COOKIES = 'SET_ACCEPTED_COOKIES';
