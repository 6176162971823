import { combineReducers } from 'redux';
import language from './page/language/reducers';
import post from './post/reducers';
import me from './me/reducers';
import snackbar from './snackbar/reducers';
import file from './file/reducers';
import news from './news/reducer';
import newsElement from './news/element/reducer';
import procurements from './procurements/reducer';
import procurementElement from './procurements/element/reducer';
import sights from './sights/reducer';
import sight from './sights/sight/reducer';
import hotel from './hotel/reducer';
import layout from './layout/reducer';
import community from './community/reducer';
import home from './home/reducer';
import office from './office/reducer';
import council from './council/reducer';
import gazette from './officialGazette/reducer';
import common from './common/reducer';
import cookies from './page/cookies/reducer';

export default combineReducers({
	language,
	post,
	me,
	snackbar,
	file,
	cookies,
	procurements: combineReducers({
		list: procurements,
		element: procurementElement
	}),
	news: combineReducers({
		list: news,
		element: newsElement
	}),
	hotel: combineReducers({
		list: hotel
	}),
	layout,
	sight,
	community: combineReducers({
		list: community
	}),
	home,
	gazette: combineReducers({
		list: gazette
	}),
	sights: combineReducers({
		list: sights,
		element: sight
	}),
	office: combineReducers({
		list: office
	}),
	council: combineReducers({
		list: council
	}),
	common
	/* demos: combineReducers({
        lists: demoList,
        element: demoElement
    }) */
});
