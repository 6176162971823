import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import YouTube from 'react-youtube';
import FacebookPlayer from 'react-facebook-player';
import {
	FileSelector,
	ImageSelector,
	MultipleFileSelect
} from '../../../components/modals';
import {
	Select,
	TextField,
	RichTextEditor,
	Slider
} from '../../../components/form';
import EnableRow from './EnableRow';
import Gallery from '../../../components/form/Gallery';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { item, open, setOpen, onConfirm, t } = props;
	const [fileList, setFileList] = useState([]);
	const overrideValues = (values) => {
		let attachments = fileList.map((item, index) => ({
			num: index + 1,
			fileId: item.id
		}));
		console.log(attachments);
		return {
			attachment: { content: [...attachments] },
			content: values.content
		};
	};

	const classes = useStyles();

	useEffect(() => {
		if (item.attachment?.content) {
			console.log('settel');
			setFileList(item.attachment.content.map((attachment) => attachment.file));
		}
	}, [item]);

	return (
		<Formik
			enableReinitialize
			initialValues={{
				content: item
					? item.content
					: [
							{
								lang: 'hu'
							},
							{
								lang: 'ro'
							}
					  ],
				attachment: item
					? item.attachment
					: {
							content: [
								{
									num: 1,
									fileId: null
								},
								{
									num: 2,
									fileId: null
								},
								{
									num: 3,
									fileId: null
								},
								{
									num: 4,
									fileId: null
								},
								{
									num: 5,
									fileId: null
								},
								{
									num: 6,
									fileId: null
								}
							]
					  }
			}}
			onSubmit={(values) => {
				onConfirm(overrideValues(values));
				setOpen(false);
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{item?.content[0]?.title ? item?.content[0]?.title : 'Új elem'}
					</DialogTitle>
					<DialogContent>
						<EnableRow
							active={item.active}
							itemId={item.id}
							onFinish={() => setOpen(false)}
						/>
						<Form>
							<FieldArray
								name='content'
								render={() => (
									<>
										<Box style={{ display: 'flex', flexDirection: 'row' }}>
											{values.content &&
												values.content.map((item, index) => (
													<Box key={index} className={classes.fieldArrayItem}>
														<Typography variant='h2'>
															{t(`common:${item.lang}`)}
														</Typography>

														<Field
															name={`content[${index}].title`}
															label={t('common:newsCreateTitle')}
															component={TextField}
															error={!item.title || item.title.length === 0}
														/>

														<Field
															name={`content[${index}].shortDesc`}
															label={t('common:newsCreateShortDesc')}
															component={TextField}
															multiline
															error={
																!item.shortDesc || item.shortDesc.length === 0
															}
															minRows={2}
														/>
													</Box>
												))}
										</Box>
										<MultipleFileSelect
											files={fileList}
											disableRemove
											setFileList={(val) => {
												setFileList(val);
											}}
										/>
									</>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='default'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='secondary'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
