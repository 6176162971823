import store from '../store';
import { GET_ALL_PROCUREMENTS } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const getAllProcurements = async (params) => {
	await api
		.get(`procurements?${params ? params : 'page=1'}`)
		.then((response) => {
			if (response.data) {
				const procurements = response.data;
				const oldItems = procurements.items;
				const newItems = oldItems.filter((proc) => proc.content.length >= 1);
				procurements.items = newItems;
				store.dispatch({
					type: GET_ALL_PROCUREMENTS,
					payload: procurements
				});
			}
		});
};

export const getElements = (other) => {
	api
		.get(other ? `/procurements${other}` : '/procurements?active=true')
		.then((response) => {
			store.dispatch({ type: GET_ALL_PROCUREMENTS, payload: response.data });
		});
};

export const saveElement = async (data) => {
	return api
		.post('/procurements', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getElements('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});
};

export const updateElement = (id, data) => {
	api
		.put(`/procurements/${id}`, data)
		.then(() => getElements('?active=false'))
		.catch((e) => console.log(e));
};

export const deleteElement = (id) => {
	api.delete(`/procurements/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/procurements/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
