import React, { useState } from 'react';
import {
	Box,
	makeStyles,
	Typography,
	withWidth,
	CardMedia,
	Paper,
	GridList,
	GridListTile,
	useMediaQuery,
	Card,
	CardActionArea
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/min/locales';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import Carousel from 'react-material-ui-carousel';
import Lightbox from 'react-modal-image/lib/Lightbox';
import { Header } from './components/Header';
import { PostBody } from '../../../../components/layout';
import { NavigateBack, Village } from '../../../../components/icons';
import palette from '../../../../themes/palette';
import FacebookPlayer from 'react-facebook-player';

import { FileCard } from '../../Council/components/FileCard';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		width: '100%',
		height: '600px',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	pageContainer: {},
	mainContainer: {
		position: 'relative'
		// marginLeft: '2px'
	},
	titleContainer: {
		marginRight: '39.375%',
		marginLeft: '19.0625%',
		top: '540px',
		zIndex: 100
	},
	titleBox: {
		// marginRight: "39.375%",
		// marginLeft: "19.0625%",
		marginRight: '39.375%',
		marginLeft: '15.0625%',
		// top: "540px",
		background: palette.primary.dark,
		height: '116px',
		zIndex: 201,
		position: 'relative',

		[theme.breakpoints.only('xs')]: {
			top: 0,
			height: 'fit-content',
			marginLeft: -20,
			marginRight: -20,
			marginTop: -50,
			width: 'auto'
		},
		[theme.breakpoints.up('sm')]: {
			top: 0,
			height: 'fit-content',
			marginLeft: -20,
			marginRight: -20,
			marginTop: -50,
			width: 'auto'
		}
	},
	titleText: {
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		color: '#FAFAF5',
		paddingLeft: 30,
		paddingTop: 21,
		paddingBottom: 21,
		paddingRight: 27,

		[theme.breakpoints.only('xs')]: {
			fontSize: 20,
			paddingLeft: 20,
			paddingTop: 10,
			paddingBottom: 10,
			paddingRight: 20
		}
	},
	infoContainer: {
		width: '100%',
		height: 'fit-content',
		top: 0,
		left: 0
	},
	contentContainer: {
		width: '58.75%',
		background: '#FAFAF5',
		height: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingLeft: 72,
		paddingRight: 72,
		// paddingTop: 112,
		paddingBottom: 72,
		display: 'table',
		marginBottom: '100px',
		[theme.breakpoints.only('xs')]: {
			paddingTop: 0,
			paddingRight: 20,
			paddingLeft: 20,
			marginLeft: 20,
			marginRight: 20,
			width: 'auto',
			height: 'fit-content'
		},
		[theme.breakpoints.only('sm')]: {
			paddingRight: 40,
			paddingLeft: 40,
			marginLeft: 40,
			marginRight: 40,
			width: '78%',
			height: 'fit-content'
		}
	},
	dateText: {
		color: '#E0B080',
		fontSize: '16px',
		lineHeight: '18px',
		paddingBottom: '21px',
		borderBottom: '1px solid #D5D4C9',
		marginBottom: '30px',
		fontWeight: 600,
		paddingTop: 50,
		[theme.breakpoints.only('xs')]: {
			paddingTop: 50
		}
	},
	contentText: {
		lineHeight: '32px',
		fontSize: '14px',
		fontWeight: 600,
		color: '#070c35'
	},
	articleFooter: {
		paddingTop: '100px',
		borderTop: '1px solid #D5D4C9',
		marginTop: '24px'
	},
	navigateIconContainer: {
		top: 50,
		left: 50,
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			top: 30,
			left: 30
		}
	},
	imageBox: {
		width: '100%',
		maxHeight: '600px',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		minWidth: 0,
		marginLeft: 0,
		height: 'fit-content'
	},
	img: {
		width: '100%'
	},
	youtubeBox: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	facebookBox: {
		width: '60%',
		margin: '20px auto',
		[theme.breakpoints.down('md')]: {
			width: '80%'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	carousel: {
		height: '460px',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			width: '100%'
		}
	},
	picContentBox: {
		height: '200px',
		width: '100%',
		borderRadius: 0
	},
	picBox: {
		overflow: 'hidden',

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#F0F0EB',
		border: '4px solid #FAFAF5',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	media: {
		display: 'flex',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		height: '100%'
		// minHeight: '300px',
		// [theme.breakpoints.down('md')]: {
		// 	minHeight: '10.7vw'
		// },

		// [theme.breakpoints.down('sm')]: {
		// 	minHeight: '50.7vw'
		// }
		// width: '100%'
	},
	itemMedia: {
		height: '460px'
	}
}));

function Item(props) {
	return (
		<Paper>
			<CardMedia
				className={props.classes.itemMedia}
				image={props.item.file.url}
				title='Contemplative Reptile'
			/>
		</Paper>
	);
}

function NewsPage(props) {
	const history = useHistory();
	const { createdAt, content } = useSelector((state) => state.news.element);
	const newsSlider = useSelector((state) => state.news.element.slider);
	const newsGallery = useSelector((state) => state.news.element.gallery);
	const language = useSelector((state) => state.language.language);
	const filteredContent =
		content && content.length > 0
			? content.filter((item) => item.lang === language)[0]
			: null;

	const classes = useStyles();
	const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	const [image, setImage] = useState(null);

	const goBack = () => {
		window.scrollTo(0, 0);

		history.goBack();
	};

	const getGridListCols = () => {
		switch (true) {
			case lgUp:
				return 4;
			case mdUp:
				return 3;
			case smUp:
				return 2;
			default:
				return 1;
		}
	};

	return (
		<Box>
			<Header />
			{filteredContent && (
				<Box className={classes.mainContainer}>
					<Box className={classes.infoContainer}>
						<Box className={classes.imageBox}>
							{/* TODO ide Image kerul
                            <Image
                                image={filteredContent.headerImage}
                            /> */}
							<img
								className={classes.img}
								src={filteredContent.headerImage.url}
								alt={filteredContent.title}
							/>
						</Box>

						<Box className={classes.contentContainer}>
							<Box className={classes.titleBox}>
								<Typography className={classes.titleText}>
									{filteredContent.title}
								</Typography>
							</Box>
							<Box className={classes.dateContainer}>
								<Typography className={classes.dateText}>
									{moment(createdAt).locale(language).format('MMMM DD, YYYY')}
								</Typography>
							</Box>
							<Typography className={classes.contentText}>
								<PostBody content={filteredContent.content} />
							</Typography>
							{filteredContent.youtubeId && (
								<YouTube
									className={classes.youtubeBox}
									videoId={filteredContent.youtubeId} // defaults -> null
									id={filteredContent.youtubeId} // defaults -> null
								/>
							)}
							{filteredContent.facebookId && (
								<Box className={classes.facebookBox}>
									<FacebookPlayer
										classNAme={classes.facebookBox}
										videoId={filteredContent.facebookId}
										appId={361360825797642}
									/>
								</Box>
							)}
							{newsSlider && newsSlider.content.length > 0 && (
								<Carousel
									navButtonsAlwaysVisible
									className={classes.carousel}
									indicators={false}>
									{newsSlider.content.map((item, i) => (
										<Item key={i} item={item} classes={classes} />
									))}
								</Carousel>
							)}

							<Box className={classes.articleFooter}>
								{filteredContent.attachment && (
									<FileCard
										border
										file={{ content: [{ file: filteredContent.attachment }] }}
									/>
								)}
							</Box>
							{newsGallery &&
								newsGallery.content &&
								newsGallery.content.length > 0 && (
									<GridList
										cellHeight={200}
										classes={{ root: { paddingBottom: 100 } }}
										spacing={0}
										cols={getGridListCols()}>
										{newsGallery.content.map((item, i) => (
											<GridListTile
												key={item.file.id}
												cols={1}
												onClick={() => setImage(item)}
												className={classes.picBox}>
												<Card className={classes.picContentBox}>
													<CardActionArea>
														<CardMedia
															component='img'
															alt=''
															height='200'
															width='100%'
															image={`${item.file.url}?size=lg`}
															title='Contemplative Reptile'
														/>
													</CardActionArea>
												</Card>
											</GridListTile>
										))}
									</GridList>
								)}
						</Box>
					</Box>
					<Box className={classes.navigateIconContainer}>
						<NavigateBack onButtonClick={goBack} />
					</Box>
				</Box>
			)}
			{image && (
				<Lightbox
					medium={`${image.file.url}?size=lg`}
					large={`${image.file.url}?size=lg`}
					alt=''
					onClose={() => setImage(null)}
				/>
			)}
			<Box>
				<Village />
			</Box>
		</Box>
	);
}
export default withWidth()(NewsPage);
