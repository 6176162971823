import React, { useRef } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { getIn } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import palette from '../../themes/palette';

const useStyles = makeStyles(() => ({
	editor: {
		border: `1px solid ${palette.primary.main}`
	}
}));

function RichTextEditor(props) {
	const classes = useStyles();

	const {
		field,
		form: { dirty, touched, errors, setFieldValue },
		options,
		label,
		textFieldProps,
		editorState,
		defaultValue,
		...other
	} = props;

	const errorText = getIn(errors, field.name);
	const touchedVal = getIn(touched, field.name);
	const hasError = dirty && touchedVal && errorText !== undefined;

	const ref = useRef(null);

	const onSave = (value) => {
		setFieldValue(field.name, value);
	};
	const value = defaultValue || '';
	return (
		<MUIRichTextEditor
			label={label}
			className={classes.editor}
			defaultValue={value}
			error={hasError}
			ref={ref}
			onBlur={() => ref.current.save()}
			onSave={onSave}
			{...other}
		/>
	);
}
/*
RichTextEditor.propTypes = {
    label: PropTypes.string.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        touched: PropTypes.object,
        errors: PropTypes.object,
        values: PropTypes.object,
        setFieldValue: PropTypes.func,
    }).isRequired,
    theme: PropTypes.shape({ palette: PropTypes.object }),
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
} */

RichTextEditor.defaultProps = {
	theme: {},
	required: false,
	fullWidth: true,
	margin: 'normal'
};

export default RichTextEditor;
