import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';
import store from '../store';
import { VIEW_IMAGE, FILE_TYPE } from '../types';

export const getImages = (params) => {
	const { limit, page } = params;
	api.get(`/files?type=img&limit=${limit}&page=${page}`).then((response) => {
		store.dispatch({ type: FILE_TYPE.IMAGE, payload: response.data });
	});
};

export const getFiles = (params) => {
	const { limit, page } = params;
	api.get(`/files?type=doc&limit=${limit}&page=${page}`).then((response) => {
		store.dispatch({ type: FILE_TYPE.ATTACHMENT, payload: response.data });
	});
};

export const saveFile = (fileData) => {
	const { file, type, selected } = fileData;
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	};

	const fd = new FormData();
	fd.append('file', file);

	if (type === FILE_TYPE.IMAGE) {
		api
			.post('/files/image', fd, config)
			.then((response) => {
				if (typeof selected == 'function') {
					api
						.get('files/data/' + response.data.id)
						.then((response) => {
							selected(response.data);
						})
						.catch(() => {
							openSnackbar({ message: 'valami nem jo', severity: 'error' });
						});
				}
				getImages({ limit: 10, page: 1 });
			})
			.catch(() => {
				openSnackbar({ message: 'valami nem jo', severity: 'error' });
			});
	} else if (type === FILE_TYPE.ATTACHMENT) {
		api
			.post('/files/docs', fd, config)
			.then((response) => {
				if (typeof selected == 'function') {
					api
						.get('files/data/' + response.data.id)
						.then((response) => {
							selected(response.data);
						})
						.catch(() => {
							openSnackbar({ message: 'valami nem jo', severity: 'error' });
						});
				}
				getFiles({ limit: 10, page: 1 });
			})
			.catch(() => {
				openSnackbar({ message: 'valami nem jo', severity: 'error' });
			});
	}
};

export const viewImage = (data) => {
	store.dispatch({ type: VIEW_IMAGE, payload: data });
};

export const deleteFile = (id, type) => {
	api
		.delete('files/' + id)
		.then(() => {
			if (type === FILE_TYPE.IMAGE) {
				getImages({ limit: 10, page: 1 });
			} else {
				getFiles({ limit: 10, page: 1 });
			}

			openSnackbar({
				message: 'Sikeres törlés!',
				severity: 'success'
			});
		})
		.catch(() => {
			openSnackbar({
				message: 'A törlés sikertelen! Ez az elem használatban van!',
				severity: 'error'
			});
		});
};
