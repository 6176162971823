import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { Document1 } from '../../../../../components/icons';
import { useHistory } from 'react-router-dom';

const BP1 = '@media (max-width: 610px)';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 180,
		background: '#FAFAF5',
		paddingBottom: '16px',
		paddingRight: '10px',
		paddingLeft: '10px',
		marginLeft: '44px',
		marginRight: '24px',
		marginBottom: theme.spacing(5),
		cursor: 'pointer'
	},
	content: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row'
	},
	border: {
		border: '1px solid #D5D4C9',
		borderRadius: 4
	},
	iconBox: {
		// position: 'relative',
		height: 192,
		width: 192,
		minWidth: 192,
		backgroundColor: '#FFFFFF',
		marginLeft: '-30px',
		marginTop: '-20px'
	},
	iconContainer: {
		width: 102,
		marginTop: 55,
		marginLeft: 45
	},
	title: {
		marginTop: 20,
		marginLeft: 24,
		transition: 'all .3s',
		[BP1]: {
			marginLeft: 8
		}
	},
	cardTitle: {
		color: theme.palette.primary.dark,
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: 20,
		transition: 'all .3s',
		overflowWrap: 'break-word',
		[BP1]: {
			fontSize: 18
		}
	},
	cardSubTitle: {
		color: '#E0B080',
		fontFamily: 'Ubuntu',
		fontWeight: 'medium',
		fontSize: '16px',
		transition: 'all .3s',
		marginTop: 10,
		[BP1]: {
			fontSize: '14px',
			marginTop: '8px'
		}
	},
	buttonContainer: {
		// width: 176,
		marginLeft: 'auto',
		marginRight: 20,
		marginTop: 20
	},
	anchor: {
		marginLeft: 'auto',
		marginRight: 'auto',
		textDecoration: 'none'
	},
	button: {
		width: 160,
		height: 60,
		color: theme.palette.primary.contrastText,
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		fontSize: '18px',
		transition: 'all .3s',
		'&:hover': {
			backgroundColor: '#48B3C9',
			color: '#FFFFFF'
		},
		[BP1]: {
			width: 140,
			height: 40
		}
	},
	rightContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	bigBtnContainer: {
		width: '160px',
		marginLeft: '8px'
	},
	bottomTextContainer: {
		marginTop: '20px',
		marginBottom: '8px'
	},
	bottomBtnContainer: {
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));

function FileCard(props) {
	const classes = useStyles();

	const { title, shortDescr, id } = props;
	const history = useHistory();
	return (
		<>
			<Box
				className={classes.root}
				onClick={() => {
					history.push(`procurement/${id}`);

					window.scrollTo(0, 0);
				}}>
				<Box className={classes.content}>
					<Box className={classes.iconBox}>
						<Box className={classes.iconContainer}>
							<Document1 width='120px' height='80px' />
						</Box>
					</Box>
					<Box className={classes.rightContainer}>
						<Box className={classes.title}>
							<Typography className={classes.cardTitle}>{title}</Typography>
							<Typography className={classes.cardSubTitle}>
								{shortDescr}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
}
export default withNamespaces('council')(FileCard);
