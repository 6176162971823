import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { TextField } from '../../../components/form';
import { MultipleFileSelect } from '../../../components/modals';
import { openSnackbar } from '../../../store/snackbar/actions';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '90vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px',
		width: '50%'
	}
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { open, setOpen, onConfirm, t } = props;
	const [fileList, setFileList] = useState([]);
	const classes = useStyles();

	return (
		<Formik
			initialValues={{
				content: [
					{
						lang: 'hu'
					},
					{
						lang: 'ro'
					}
				],
				attachment: {
					content: [
						{
							num: 1,
							fileId: null
						},
						{
							num: 2,
							fileId: null
						},
						{
							num: 3,
							fileId: null
						},
						{
							num: 4,
							fileId: null
						},
						{
							num: 5,
							fileId: null
						},
						{
							num: 6,
							fileId: null
						}
					]
				}
			}}
			onSubmit={(values, { resetForm }) => {
				if (!fileList.length) {
					openSnackbar({
						message: 'Kötelező csatolmányt hozzaadni!',
						severity: 'error'
					});
				} else {
					let attachments = fileList.map((item, index) => ({
						num: index + 1,
						fileId: item.id
					}));
					onConfirm({
						content: values.content,
						attachment: { content: attachments }
					}).then((result) => {
						if (result) {
							resetForm({});
							setOpen(false);
						}
					});
				}
			}}>
			{({ values, submitForm }) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					size='xl'
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{t('newelementProcurements')}
					</DialogTitle>
					<DialogContent style={{ width: '70vw' }}>
						<Form>
							<FieldArray
								name='content'
								render={() => (
									<>
										<Box
											style={{
												display: 'flex',
												flexDirection: 'row'
											}}>
											{values.content &&
												values.content.map((item, index) => (
													<Box key={index} className={classes.fieldArrayItem}>
														<Typography variant='h2'>
															{t(`common:${item.lang}`)}
														</Typography>

														<Field
															name={`content[${index}].title`}
															label={t('common:newsCreateTitle')}
															component={TextField}
															error={!item.title || item.title.length === 0}
														/>

														<Field
															name={`content[${index}].shortDesc`}
															label={t('common:newsCreateShortDesc')}
															component={TextField}
															multiline
															error={
																!item.shortDesc || item.shortDesc.length === 0
															}
															minRows={2}
														/>
													</Box>
												))}
										</Box>
										<MultipleFileSelect
											files={null}
											setFileList={(val) => {
												setFileList(val);
											}}
										/>
									</>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='secondary'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='default'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
