import React, { useEffect } from 'react';
import {
	Typography,
	Box,
	makeStyles,
	withWidth,
	Collapse
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomePic from '../../../../components/images/Home_02.png';
import {
	HomeScreenHalfCircle,
	MenuTriangleReverse
} from '../../../../components/icons';
import { News, MonumentHistory, MadefalvaEl } from '.';
import {
	SiculicidiumHistory12,
	MadefalvaHistory2
} from '../../History/components';
import { showLargeMenu } from '../../../../store/layout/actions';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		width: '100%',
		height: '548px',
		zIndex: 1,
		background: `transparent url(${HomePic}) 0% 0% no-repeat padding-box`,
		backgroundSize: 'cover'
	},
	pageContainer: {
		position: 'relative',

		'& *': {
			MsOverflowStyle: 'none',
			scrollbarWidth: 'none'
		}
	},
	divider: {
		width: '100%',
		position: 'absolute',
		bottom: -2,
		height: 58
	},
	headerContainer: {
		height: 548,
		position: 'relative'
	},
	titleBox: {
		width: 1128,
		position: 'absolute',
		top: 226,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	titleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: '#070C35',
		fontSize: '50px',
		letterSpacing: '-1px',
		lineHeight: '57px',
		marginTop: 6,
		marginLeft: 20,
		marginBottom: 8,
		marginRight: 30,
		zIndex: 102
	},
	titleContainer: {
		position: 'absolute',
		top: 0,
		width: 'fit-content',
		background: '#FEFEFE',
		left: 0
	},
	titleContainer2: {
		position: 'absolute',
		top: 85,
		width: 'fit-content',
		left: 161,
		zIndex: 2
	},
	titleText2: {
		color: '#FAFAF5',
		fontSize: '30px',
		letterSpacing: '0',
		lineHeight: '35px'
	},
	halfCircle: {
		width: 192,
		height: 96,
		position: 'absolute',
		top: 70,
		left: 113,
		zIndex: 1
	},
	contentContainer: {
		width: 1128,
		height: 596,
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.only('md')]: {
			width: '100%',
			marginLeft: 30,
			marginRight: 30
		}
	},
	newsTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: '#070C35',
		fontSize: '32px',
		letterSpacing: '0px',
		lineHeight: '34px',
		marginBottom: 25
	},
	newsDescription: {
		fontFamily: 'Ubuntu',
		color: '#070C35',
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '24px'
	},
	newsContainer: {
		width: '100%'
	},
	sicHistory: {
		height: '100vh'
	},
	madHistory: {
		height: '100vh'
	},
	monHistory: {
		height: '100vh'
	},

	arrowButton: {
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		left: 0,
		right: 0,
		marginTop: 0,
		zIndex: 100,
		position: 'absolute',
		top: 518,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	navigationIconButton: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF'
		},
		transform: 'rotate(90deg)'
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box',
		opacity: 1
	},
	bottomDescoration: {
		height: 300,
		width: '100%',
		position: 'absolute',
		bottom: 0,
		backgroundColor:
			'transparent linear-gradient(180deg, #7993A200 0%, #3D4A51 100%) 0% 0% no-repeat padding-box'
	}
}));

function HomeAnimated(props) {
	const classes = useStyles();
	const { t } = props;

	const largeMenu = useSelector((state) => state.layout.showLargeMenu);

	const handleScroll = () => {
		if (window.scrollY <= 50 && window.innerWidth > 1277) {
			showLargeMenu(true);
		} else {
			showLargeMenu(false);
		}
	};

	useEffect(() => {
		showLargeMenu(true);
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Box
			className={classes.pageContainer}
			style={{
				zIndex: 150
			}}>
			<Collapse in={largeMenu} timeout={600}>
				<Box style={{ height: 260 }} />
			</Collapse>
			<Box>
				<Box className={classes.headerContainer}>
					<Box className={classes.imageContainer} />

					<Box className={classes.titleBox}>
						<Box className={classes.titleContainer}>
							<Typography className={classes.titleText}>
								{t('titleText1')}
							</Typography>
						</Box>
						<Box className={classes.titleContainer2}>
							<Typography className={classes.titleText2}>
								{t('titleText2')}
							</Typography>
						</Box>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#070C35' />
						</Box>
					</Box>
					<Box className={classes.bottomDescoration}>
						<Box className={classes.divider}>
							<MenuTriangleReverse width='100%' height='100%' fill='#F0F0EB' />
						</Box>
					</Box>
					{/* <Box className={classes.arrowButton}>
						<IconButton className={classes.navigationIconButton}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
					</Box> */}
				</Box>

				<Box className={classes.contentContainer}>
					<Box className={classes.newsContainer}>
						<News />
					</Box>
				</Box>
			</Box>

			<SiculicidiumHistory12 />

			<MadefalvaHistory2 />
			<MonumentHistory />
			<MadefalvaEl />
		</Box>
	);
}

export default withWidth()(withNamespaces('home')(HomeAnimated));
