import store from '../../store';
import { GET_PROCUREMENTS } from '../../types';
import { api } from '../../../lib/axios/api';
import { openSnackbar } from '../../snackbar/actions';

export const saveProcurement = async (data) => {
	await api
		.post('/procurements', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
		});
};

export const getProcurement = async (id) => {
	await api.get(`/procurements/${id}`).then((response) => {
		store.dispatch({
			type: GET_PROCUREMENTS,
			payload: response.data
		});
	});
};
