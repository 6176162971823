import {
	Box,
	Card,
	CardContent,
	CardHeader,
	makeStyles,
	List,
	IconButton
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { withNamespaces } from 'react-i18next';
import { ListItem } from './components/ListItem';
import { Create } from '../Create';
import {
	saveElement as saveItem,
	getByCategory as getData
} from '../../../store/common/actions';

import { COMMON_CATEGORIES } from '../../../config/common-categories';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '30px',
		maxWidth: '1500px',
		margin: 'auto'
	},
	card: {},
	pagination: {
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));
export default withNamespaces('common')((props) => {
	const classes = useStyles();
	const { t } = props;
	const data = useSelector((state) => state.common);

	const [open, setOpen] = useState(false);
	const createItem = async (newItem) => saveItem(newItem);

	useEffect(() => {
		getData(COMMON_CATEGORIES.TV);
	}, []);

	return (
		<Box className={classes.root}>
			<Card className={classes.card}>
				<CardHeader title={t('amade-tv')} />
				<CardContent>
					{(!data || !data.length) && (
						<IconButton onClick={() => setOpen(true)}>
							<AddIcon />
						</IconButton>
					)}
					<Create open={open} setOpen={setOpen} onConfirm={createItem} />
					<List>
						{data &&
							data.length &&
							data.map((item) => <ListItem item={item} />)}
					</List>
				</CardContent>
			</Card>
		</Box>
	);
});
